/* eslint-disable */
/* Warning: this file is autogerated, any changes would be lost */
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import WpAcfTextActionBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfImageBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfIconTextBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfTitleGridBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfImageCarouselBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpAcfImageTitleActionBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'



const Blocks = ({ blocks = [], previewUUID }) => {
  return (
    <>
      {useMemo(() => {
        
        return blocks
      }, [blocks, previewUUID]).map((block, i) => {
        const children = block.innerBlocks ? <Blocks blocks={block.innerBlocks} previewUUID={previewUUID} /> : null;

        if (block.__typename === 'WpAcfTextActionBlock') {
            return <WpAcfTextActionBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfImageBlock') {
            return <WpAcfImageBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfIconTextBlock') {
            return <WpAcfIconTextBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfTitleGridBlock') {
            return <WpAcfTitleGridBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfImageCarouselBlock') {
            return <WpAcfImageCarouselBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpAcfImageTitleActionBlock') {
            return <WpAcfImageTitleActionBlock {...block} children={children} key={i} />
          }

        return null;
      })}
    </>
  );
};

export default ({ previewUUID }) => {

  const { wpProject: { blocks } } = useStaticQuery(graphql`
    fragment Blocks536 on WpBlock { __typename ...ThemeWordpressGutenbergUnknownBlock  }
    query {
      wpProject(id: {eq: "536"}) {
        blocks {
          ...Blocks536
        }
      }
    }`)

  return <Blocks blocks={blocks} previewUUID={previewUUID} />
}
